import React, {useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu, 
  Button, 
  Avatar,
  Badge
} from '@material-ui/core';
import GuellAppIcon from '../../static/images/app_images/guellapp.svg'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import './Header.css'
import { reactLocalStorage } from 'reactjs-localstorage';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { updateProviderStatus } from '../../services/prescriptionService';
import { getCookie, removeAllCookies, setCookie } from '../../lib/session';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    // flexGrow: 1,
  },
  large: {
    width: '50px !important',
    height: '50px !important',
  },
  larger: {
    width: '60px !important',
    height: '60px !important',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: reactLocalStorage.get('status') == 4 ? 'red' : '#44b700',
    color:  reactLocalStorage.get('status') == 4 ? 'red' : '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

/**
 * @description App header 
 * @author Jagannath
 * @date 2020-09-15
 * @export Header full component
 */
export default function Header(props) {
  const classes = useStyles();
  const [auth, setAuth] = useState(true);
  

  const [currentTime, setCurrentTime] = useState(moment().format('ll'));
  const [currentDate, setCurrentDate] = useState(moment().format('LT'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [doctorActive, setDoctorActive] = useState(true)
  const token = reactLocalStorage.get('token');
  const userStatus = reactLocalStorage.get('userStatus');
  
  /**
   * @description setting token into state on starting of component lifecycle
   * @author Jagannath
   * @date 2020-09-15
   * @export
   */
  useEffect(() => {
    token ? setAuth(true) : setAuth(false);
    userStatus ? setDoctorActive(userStatus == 3 ? true : false) : setDoctorActive(false)
  }, [anchorEl, token, userStatus])

  /**
   * @description toggling login dropdown
   * @author Jagannath
   * @date 2020-09-15
   * @param event selected item from dropdown
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @description closing dropdown 
   * @author Jagannath
   * @date 2020-09-15
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * @description changing doctor status
   * @author Jagannath
   * @date 2020-09-26
   */
  const changeProviderStatus = () =>{
    const list = {
      status: doctorActive ? 4 : 3
    }
    updateProviderStatus(list).then(
      (res)=>{
        console.log(res);
        setDoctorActive(!doctorActive)
        reactLocalStorage.set('userStatus', doctorActive ? 4 : 3)
      }
    ).catch(err=>console.log(err))
  }

  /**
   * @description small child component for doctor status button
   * @author Jagannath
   * @date 2020-09-26
   * @return {*} 
   */
  const DoctorStatus = () => {
    if (reactLocalStorage.get('userType') == 2) return (<></>)
    return (
      <button 
        type="button" 
        onClick={changeProviderStatus}
        title={doctorActive ? 'Go Offline' : 'Go Online'}
        className={`btn doctor-status-btn bold pt-1 pb-1 ${ doctorActive ? 'btn-success' : 'btn-danger'}`} >
        {doctorActive ? 'Online' : 'Offline'}
      </button>
    )
  }

  /**
   * @description remobing prestant storage and logout user
   * also redirecting user to login page
   * @author Jagannath
   * @date 2020-09-15
   */
  const logoutHandler = async() => {
    handleClose()
    await reactLocalStorage.clear()
    await removeAllCookies()
    setAuth(false)
  }

  /** updating date in time in header on every second
   * @description 
   * @author Jagannath
   * @date 2020-09-15
   */
    setInterval(() => {
      setCurrentDate(moment().format('ll'))
      setCurrentTime(moment().format('LTS'))
    }, 1000);

  // redirecting to login page if not token or on logout
  if(!auth){
    return <Redirect to="/login" />
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className={`${props.doctor ? 'doctorTrue' : 'patientTrue'}`}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" className={classes.title + ' applogo'}>
            <Link to="/">
              <img src={GuellAppIcon} height={54} width={144} alt="" />
            </Link>
          </Typography>
          <div className="doctor-status flex-grow-1">
            <DoctorStatus />
          </div>
          <div color="inherit" className="mr-1 currentDateIcon">
            <CalendarTodayIcon />
          </div>
          <div className="mr-3 currentDate">
            <Typography>
                { currentDate }
            </Typography>
          </div>
          <div color="inherit" className="mr-1">
            <AccessTimeIcon />
          </div>
          <div className="mr-3 currentTime">
            <Typography>
              { currentTime }
            </Typography>
          </div>
          <div 
            title="logout"
            color="inherit" 
            className="mr-3 ml-3 header-icon d-flex"  
            aria-controls="simple-menu" 
            aria-haspopup="true" 
            onClick={handleClick}>
              <Avatar alt={getCookie('username')} src={getCookie('profilePic')} className={classes.large}>
                {getCookie('username')}
              </Avatar>
              <Typography className="userEmail">
                {getCookie('username')}
              </Typography>
          </div>
          <Menu
            id="simple-menu"
            className="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div className="menu-items-1">
              <MenuItem onClick={handleClose} className="d-flex justify-content-center">
                <StyledBadge
                  overlap="circle"
                  
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar alt={getCookie('username')} src={getCookie('profilePic')} className={classes.larger}>
                    {getCookie('username')}
                  </Avatar>
                </StyledBadge>
              </MenuItem>
              <MenuItem  className="d-flex justify-content-center pb-0">
                <h5 className="m-0 p-0">{getCookie('username')}</h5>
              </MenuItem>
              <MenuItem  className="d-flex justify-content-center p-0 text-muted">{getCookie('email')}</MenuItem>
            </div>
            <MenuItem className="logout-div d-flex" onClick={logoutHandler}>
              <LockIcon className="m-3"/>
              <MenuItem component="div" className="p-0" >Logout</MenuItem>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}