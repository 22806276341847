import { Avatar, Button, Card, CardHeader, IconButton, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import './RatingReview.css';
import { Rating } from '@material-ui/lab';
import moment from 'moment'
import { providerBookingById } from '../../services/service';
import { reactLocalStorage } from 'reactjs-localstorage';
import { postReviewRating, createPrescription } from '../../services/ratingService';
import { Redirect } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export class RatingReview extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             item:'',
             rating: null,
             review: '',
             bookingId: reactLocalStorage.get('bookingId'),
             isHomePage:false,
             isPrescriptionAvailbale: true,
             isLoading: false
        }
    }
    componentDidMount(){
        this.setState({rating:5});
    }

    /**
     * @description method to submit reveiw ratings
     * @author Jagannath
     * @date 2020-09-29
     */
    onSubmitRatingReview = () =>{
        const payload = {
            bookingId: this.state.bookingId,
            review: this.state.review,
            rating: this.state.rating
        }
        this.setState({isLoader: true})
        postReviewRating(payload).then(
            (res)=>{
                console.log('Review added successfully!');
                const list = {
                    status: 20,
                    latitude: this.props.bookingData.latitude,
                    longitude: this.props.bookingData.longitude,
                    bookingId: this.state.bookingId
                }
                createPrescription(list).then(
                    ()=>{
                        setTimeout(() => {
                            this.setState({
                                isHomePage: true,
                                isLoader: false
                            });
                        }, 2000);
                    }
                ).catch(
                    err=>{
                        console.log(err);
                        this.setState({isLoader: false})
                    }
                )
            }
        ).catch(err=>{
            console.log(err);
            this.setState({isLoader: false})
        })
    }

    /**
     * @description method to show priscription pdf
     * @author Jagannath
     * @date 2020-09-29
     */
    showPrescription = () =>{
        providerBookingById(this.state.bookingId).then(
            (res)=>{
                if(res.data.data.prescriptionUrl){
                    return window.open(res.data.data.prescriptionUrl, '_blank')
                }else{
                    return this.setState({isPrescriptionAvailbale: false})
                }
            }
        ).catch(err=>console.log(err))
    }
    
    render() {
        if(this.state.isHomePage){
            return <Redirect to="/" />
        }
        const Data = this.props.bookingData;
        return (
            <div className="rating-card">
                <div className="card-header rating-header text-center d-flex justify-content-center">
                    <div className="" >
                        <p className="m-0">Booking On</p>
                        <p className="m-0">
                            {moment(Data.bookingRequestedFor*1000).format('DD MMM YYYY')},
                            {' '}
                            {moment(Data.bookingRequestedFor*1000).format('LT')}
                        </p>
                    </div>
                    <CloseIcon 
                        className="rating-close-icon close-icon text-right" 
                        onClick={()=>this.props.handleClose()} /> 
                </div>
                <div className="card-body text-center d-flex flex-column">
                    <p className="text-muted">Total Bill Amount</p>
                    <h4 className="bold">{Data.currencySymbol + Data.accounting.total}</h4>
                    <button
                        type="button"
                        onClick={this.showPrescription}
                        className="btn btn-outline-default btn-sm rating-prescription-btn">
                        Prescription
                    </button>
                    {
                    !this.state.isPrescriptionAvailbale ? 
                        <p className="text-danger pt-2 m-0">No Prescription available for this booking</p> : <></>
                    }
                </div>
                <hr className="m-0"/>

                <div className="card-body text-center d-flex align-items-center flex-column">
                    <Avatar
                        alt={Data.firstName}
                        src={Data.profilePic} 
                        className={'thankyou-avatar'} 
                        >
                        {Data.firstName}
                    </Avatar>
                    <h5 className="bold">{Data.firstName + ' ' + Data.lastName}</h5>
                </div>
                <hr className="m-0"/>

                <div className="card-body text-center d-flex flex-column pb-5">
                    <Rating 
                        name="size-large"
                        value={this.state.rating}
                        onChange={(event, newValue)=>this.setState({rating: newValue})}
                        defaultValue={5} 
                        size="large" />
                    <TextField 
                        id="textfield"
                        multiline 
                        placeholder="How was the customer behaviour?"
                        className="pt-3"
                        color="primary"
                        value={this.state.review}
                        onChange={(e)=>this.setState({review: e.target.value})}
                        // label="Outlined" 
                        // variant="outlined"
                        />
                </div>
                <footer className="card-footer text-center">
                    <Button
                        variant="contained" 
                        className="bold btn-rating" 
                        size="large"
                        type="button"
                        onClick={this.onSubmitRatingReview}
                        color="primary" >
                        Done
                    </Button>
                </footer>
                <Loader isLoader={this.state.isLoader} />
            </div>
        )
    }
}

export default RatingReview
