
/**
 * @description vaig calling app configurations 
 * here you can overwrite config file of guellapp calling app
 * @author Jagannath
 * @date 2020-09-15
 */
export const config = {
    configOverwrite: { },
    interfaceConfigOverwrite: { }
}