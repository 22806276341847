import React, { Component } from 'react'
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider
} from '@material-ui/core';
import moment from 'moment';

import './style.css'
import CloseIcon from '@material-ui/icons/Close';
import { getCallStatus, getConferenceToken } from '../../services/service';
import { baseUrl } from '../../global/global';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { bookingDetails } from '../../redux/actions/bookingdetails';
import VirgilSecurity from '../../components/virgilSecurity/VirgilSecurity';

/**
 * @description Component for User Profile dialog
 * @author Jagannath
 * @date 2020-09-15
 * @export UserProfileDialog component
 * @class UserProfileDialog
 */
export class UserProfileDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            item: '',
            sessionExpired: false,
            QuesAns: []
        }
        
    }

    /**
     * @description method to open conference page
     * calling api to get conference token
     * @author Jagannath
     * @date 2020-09-15
     */
    openConference = () => {
        getConferenceToken(this.props.data.bookingId)
            .then(async (res) => {
                if(reactLocalStorage.get('userType') == 2){
                    const isCallActive = await this.getCallStatus(this.props.data.bookingId)
                    if(isCallActive){
                        reactLocalStorage.set('conferenceToken', res.data.data.token)
                        window.open(`${baseUrl}conference`)
                    }else{
                        window.alert('Wait for the Doctor to start meeting')
                    }
                }else{
                    reactLocalStorage.set('conferenceToken', res.data.data.token)
                    window.open(`${baseUrl}conference`)
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isBackdrop: false })
            })
    }

    /**
     * @description getting call status for customer only
     * @author Jagannath
     * @date 2020-09-16
     * @param bookingId booking id
     */ 
    getCallStatus = (bookingId) =>{
        return getCallStatus(bookingId)
        .then((res) => {
            console.log(res)
            return res.data.data.isCallActive
        })
        .catch((err) => {
            console.log(err);
        })
    }

    /**
     * @description initial method of component lifecycle
     * @author Jagannath
     * @date 2020-09-15
     */
    componentDidMount() {
        if (this.props.data.bookingEndtime * 1000 < Date.now()) {
            this.setState({
                sessionExpired: true
                })
        }
        /** calling method to decrypt encrypted question answers */
        this.getDecryptedQA()
        // this.props.setBookingDetails(this.props.data)
    }

    /**
     * @description method to set decrypted Question Answers into state
     * @author Jagannath
     * @date 2020-09-15
     * @param data QA
     */
    getDecryptedQA = () => {
        if(this.props.data.questionAndAnswer){
            VirgilSecurity(this.props.data.questionAndAnswer)
                .then((data) => {
                    this.setState({ QuesAns: data })
                })
                .catch(err => console.log(err))
        }
    }

    render() {
        const { data, profileDialogHandler } = this.props
        return (
            <div className="dialog">
                <DialogTitle className="dialog-title">
                    <span className="dialog-header">{"Appointment ID: " + data.bookingId}</span>
                    {/* <Button
                        onClick={() => profileDialogHandler(false)}
                        style={{ padding: 0, marginRight: '-20px' }} > */}
                        <CloseIcon className="profile-icon-close close-icon text-right" 
                            onClick={()=>profileDialogHandler(false)}/>
                    {/* </Button> */}
                </DialogTitle>

                <DialogContent className="body">

                    <div id="alert-dialog-description" className="body-1">
                        <p>Booked by: 
                            {reactLocalStorage.get('userType') == 2 ?
                                ' You':
                                ' ' + data.firstName + ' ' + data.lastName 
                            }
                        </p>
                        <div className="profile-left">
                            <img
                                src={data.profilePic}
                                alt="icon"
                                className="float-left rounded-circle profile-img" />
                            <div className="profile-title">
                                <h6 className="card-subtitle mt-2 ml-3 text-muted bold">
                                    {data.firstName + ' '}
                                    {data.lastName}
                                </h6>
                                <h6 className="card-subtitle mt-2 ml-3 text-muted bold">
                                    {data.gender ? data.gender + ', ': '' }
                                    {data.age ? data.age + ' Years' : ''}
                                    {' ' + data.neighbourHood || ''}
                                    <span id="categoryName">
                                        {reactLocalStorage.get('userType') == 2 ?
                                            (' ' + data.categoryName || '')
                                            : ''}
                                    </span>
                                </h6>
                                
                            </div>
                        </div>
                        <p className="text-muted mt-3 mb-0">Total Bill Amount</p>
                        <h3 className="text-muted bold">
                            {data.currencySymbol ? data.currencySymbol : data.currency}
                            {data.accounting.total || 0}
                        </h3>
                        <p className="text-muted m-0">
                            date: <b>{moment(data.bookingRequestedFor * 1000).format('LL')}</b>
                        </p>
                        <p className="text-muted">
                            Time: <b>{moment(data.bookingRequestedFor * 1000).format('LT')}</b>
                        </p>
                        <Divider className="mr-4" />
                        <div className="flex-between pt-2 pr-2">
                            <b className="text-muted">Consultation fee</b>
                            <b className="text-muted">
                                {data.currencySymbol ? data.currencySymbol : data.currency}
                                {data.accounting.amount || 0}
                            </b>
                        </div>
                        <div className="flex-between pt-2 pr-2">
                            <p className="text-muted p-0 m-0">Tax</p>
                            <p className="text-muted p-0 m-0">
                                {data.currencySymbol ? data.currencySymbol : data.currency}
                                {data.accounting.tax || 0}
                            </p>
                        </div>
                        <div className="flex-between pt-2 pr-2">
                            <p className="text-muted p-0 m-0">Discount</p>
                            <p className="text-muted p-0 m-0">
                                {data.currencySymbol ? data.currencySymbol : data.currency}
                                {data.accounting.discount || 0}
                            </p>
                        </div>
                        <Divider className="mr-4 mt-2" />
                        <div className="flex-between pt-2 pr-2">
                            <b className="text-muted">Total</b>
                            <b className="text-muted">
                                {data.currencySymbol ? data.currencySymbol : data.currency}
                                {data.accounting.total || 0}
                            </b>
                        </div>

                    </div>
                    <Divider orientation="vertical" id="hr" />
                    <div id="alert-dialog-description" className="body-2">
                        {!this.state.QuesAns.length ?
                            (<span className="no-qs">No Question Answers</span>):
                            this.state.QuesAns.map((list) => {
                                return (
                                    <div key={list.symptomId}>
                                        <h6 className="card-subtitle mt-2 mb-3 text-muted bold">
                                            {list.symptomName}
                                        </h6>
                                        {list.symptomAnswers.map((qs, i) => {
                                            return (
                                                <div className="QA mt-3" key={qs.id}>
                                                    <div className="flex">
                                                        <b className="text-muted m-0 mr-1">{i + 1 + '. '}</b>
                                                        <p className="text-muted m-0 mr-1">{'Q: '}</p>
                                                        <p className="text-muted m-0">{qs.question}</p>
                                                    </div>
                                                    <div className="flex">
                                                        <b className="text-muted m-0 mr-1">{'Ans: '}</b>
                                                        <b className="text-muted m-0">{qs.answer}</b>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div>
                </DialogContent>

                <hr className="m-0 mt-3 footer-hr" />
                <DialogActions className="footer">
                    <button
                        onClick={this.openConference}
                        color="secondary"
                        // disabled={this.state.sessionExpired}
                        className="footer-button btn btn-danger"
                        variant="contained">
                        {
                            this.state.sessionExpired ?
                                'Expired Tele-Conference' :
                                reactLocalStorage.get('userType') == 2 ?
                                    'Join Tele-Conference' :
                                    'Start Tele-Conference'
                        }
                    </button>
                </DialogActions>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBookingDetails: (value) => dispatch(bookingDetails('SET_BOOKING_DATA',value))
    }
}

export default connect(null, mapDispatchToProps)(UserProfileDialog)