import { postWithBasicAuth } from "../lib/request";

const base64encodedData = Buffer.from(`guellapp:guellapp`).toString('base64');
const token = `Basic ${base64encodedData}`;
export const doctorLogin = (data) => {
    return postWithBasicAuth(
        `provider/signIn`, 
        data, 
        token
    )
}
export const patientLogin = (data) => {
    return postWithBasicAuth(
        `customer/signIn`, 
        data, 
        token
    )
}

export const forgotPasswordProvider = (data) => {
    return postWithBasicAuth(
        'provider/forgotPassword',
        data,
        token
    )
}
export const forgotPasswordCustomer = (data) => {
    return postWithBasicAuth(
        'customer/forgotPassword',
        data,
        token
    )
}