import { 
    Button, 
    Card,
    CardHeader, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl, 
    IconButton, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField 
} from '@material-ui/core'
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './MedicationForm.css';
import { postMedication, prescriptionMetaData, updateMedication } from '../../services/prescriptionService';
import { reactLocalStorage } from 'reactjs-localstorage';


/**
 * @description Medication form dialog
 * @author Jagannath
 * @date 2020-09-18
 * @export MedicationForm component
 * @class MedicationForm
 */
export class MedicationForm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            metadata: {},
            durationDialog: false,
            instructionDialog: false,
            compoundName: '',
            drugStrength: '',
            drugStrengthUnit: '',
            drugFrequency:'',
            drugRoute:'',
            drugDirection:'',
            drugDuration:'',
            drugDurationUnit: 'Days',
            drugInstruction:'',
            submitted: false,
            isValidForm: false,
            error: {}
        }
    }

    /**
     * @description submitting medication form
     * @author Jagannath
     * @date 2020-09-18
     * @param e form event
     */
    formSubmitHandler = async(e) =>{
        e.preventDefault()
        await this.setState({submitted:true})
        await this.getErrorMsg()
        if(!this.state.isValidForm){
            return;
        }
        if (this.props.editText){
            const payload = this.getFormData()
            payload['medicationId'] = this.props.editText.id
            await updateMedication(payload)
        }else{
            await postMedication(this.getFormData())
        }
        this.props.formData(true)
    }

    componentDidMount(){
        this.getPrescriptionMetadata();
        if(this.props.editText){
            console.log(this.props.editText)
            this.setEditForm(this.props.editText)
        }

    }

    /**
     * @description method to get metadata for prescreption page
     * @author Jagannath
     * @date 2020-10-12
     */
    getPrescriptionMetadata = () => {
        prescriptionMetaData().then(
            (res)=>{
                this.setState({metadata: res.data.data});
                console.log(res.data.data)
            }
        ).catch(err=>console.log(err))
    }

    /**
     * @description response to send to parent component
     * @author Jagannath
     * @date 2020-09-18
     */
    getFormData = () =>{
        const data = this.state
        // return {
        //     compoundName: data.compoundName,
        //     drugStrength: data.drugStrength,
        //     drugStrengthUnit: data.drugStrengthUnit,
        //     drugFrequency: data.drugFrequency,
        //     drugRoute: data.drugRoute,
        //     drugDirection: data.drugDirection,
        //     drugDuration: data.drugDuration,
        //     drugDurationUnit: data.drugDurationUnit,
        //     drugInstruction: data.drugInstruction,
        // }
        return {
            bookingId: parseInt(reactLocalStorage.get('bookingId'),10),
            compoundName: data.compoundName,
            drugStrength: data.drugStrength,
            drugUnit: data.drugStrengthUnit,
            frequency: data.drugFrequency,
            drugRoute: data.drugRoute,
            direction: data.drugDirection,
            duration: (data.drugDuration+' '+data.drugDurationUnit),
            instruction: data.drugInstruction
          }
    }

    /**
     * @description setting editing data to state
     * @author Jagannath
     * @date 2020-09-18
     * @param data selected medication data
     */
    setEditForm = (data) =>{
        this.setState({
            compoundName: data.compoundName,
            drugStrength: data.drugStrength,
            drugStrengthUnit: data.drugUnit,
            drugFrequency: data.frequency,
            drugRoute: data.drugRoute,
            drugDirection: data.direction,
            drugDuration: data.duration,
            drugDurationUnit: 'Days',
            drugInstruction: data.instruction,
        })
    }

    /**
     * @description show error (validation)
     * @author Jagannath
     * @date 2020-09-18
     */
    getErrorMsg = async() => {
        var error = {...this.state.error}
        var data = this.state;
        if(data.submitted){
            !data.compoundName ?
                error.compoundName = true :
                error.compoundName = false;
            // !data.drugStrength ? 
            //     error.drugStrength = true:
            //     error.drugStrength = false;
            // !data.drugStrengthUnit ? 
            //     error.drugStrengthUnit = true:
            //     error.drugStrengthUnit = false;
            !data.drugRoute ? 
                error.drugRoute = true:
                error.drugRoute = false;
            !data.drugFrequency ? 
                error.drugFrequency = true:
                error.drugFrequency = false;
            !data.drugDirection ? 
                error.drugDirection = true:
                error.drugDirection = false;
            // !data.drugDuration ? 
            //     error.drugDuration = true:
            //     error.drugDuration = false;
            await this.setState({error})
            for(let err in error){
                if(error[err]){
                    return this.setState({isValidForm:false})
                }
            }
            return this.setState({isValidForm:true})
        }
    }
    
    /**
     * @description Rendering the final component
     * @author Jagannath
     * @date 2020-09-18
     * @return {*} 
     */
    render() {
        const drug = this.state;
        const {error, metadata } = this.state;
        return (
            <div className="medication-form">
                <Card className="card medication-header pl-5">
                    <CardHeader
                        // avatar={}
                        className="p-0 m-0 text-center pl-3"
                        action={
                        <IconButton onClick={this.props.handleClose} aria-label="settings">
                            <CloseIcon className="close-icon" /> 
                        </IconButton>
                        }
                        title="Prescription"
                        // subheader="September 14, 2016"
                    />
                </Card> 
                <div className="p-5">
                    <form className="col-12 row">
                        <TextField
                            id="standard-textarea"
                            label="Compound Name"
                            placeholder="Add Compound Name"
                            className="col-md-12"
                            onMouseOut={this.getErrorMsg}
                            value={drug.compoundName}
                            onChange={(e)=>this.setState({compoundName:e.target.value})}
                            error={error.compoundName}
                            // helperText="Compound Name is missing/invalid!"
                            required/>
                        <div className="col-md-12 p-0 pt-3">
                            <TextField
                                id="standard-number"
                                label="Drug Strength"
                                type="number"
                                placeholder="Add Drug Strength"
                                className="col-md-8 pr-3"
                                onMouseOut={this.getErrorMsg}
                                value={drug.drugStrength}
                                onChange={(e)=>this.setState({drugStrength:e.target.value})}
                                // error={error.drugStrength}
                                // helperText="Drug Strength is missing/invalid!"
                                />
                            <FormControl className="form-control col-md-4 float-right">
                                <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={drug.drugStrengthUnit}
                                    onMouseOut={this.getErrorMsg}
                                    // error={error.drugStrengthUnit}
                                    // helperText="Drug Strength Unit is missing/invalid!"
                                    onChange={(e)=>this.setState({drugStrengthUnit:e.target.value})}
                                    required>
                                    {metadata && metadata.units ? metadata.units.map((x, i)=>{
                                        return (
                                        <MenuItem 
                                            key={i}
                                            value={x.name}>
                                            {x.name}
                                        </MenuItem>
                                        )
                                    }):<MenuItem></MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                        <FormControl className="form-control col-md-12 float-right mt-4">
                            <InputLabel id="demo-simple-select-label">Frequency<em>*</em></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onMouseOut={this.getErrorMsg}
                                value={drug.drugFrequency}
                                onChange={(e)=>this.setState({drugFrequency:e.target.value})}
                                error={error.drugFrequency}
                                // helperText="Drug Frequency is missing/invalid!"
                                required>
                                    {metadata && metadata.frequency ? metadata.frequency.map((x, i)=>{
                                        return (
                                        <MenuItem 
                                            key={i}
                                            value={x.name}>
                                                {x.name}
                                            </MenuItem>
                                            )
                                    }):<MenuItem></MenuItem>}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control col-md-12 float-right mt-4">
                            <InputLabel id="demo-simple-select-label">Drug Route<em>*</em></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onMouseOut={this.getErrorMsg}
                                value={drug.drugRoute}
                                onChange={(e)=>this.setState({drugRoute:e.target.value})}
                                error={error.drugRoute}
                                // helperText="Drug Route is missing/invalid!"
                                required>
                                    {metadata && metadata.drugRoute ? metadata.drugRoute.map((x, i)=>{
                                        return (
                                        <MenuItem 
                                            key={i}
                                            value={x.name}>
                                            {x.name}
                                        </MenuItem>
                                        )
                                    }):<MenuItem></MenuItem>}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control col-md-12 float-right mt-4">
                            <InputLabel id="demo-simple-select-label">Direction<em>*</em></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onMouseOut={this.getErrorMsg}
                                value={drug.drugDirection}
                                onChange={(e)=>this.setState({drugDirection:e.target.value})}
                                error={error.drugDirection}
                                // helperText="Drug Direction is missing/invalid!"
                                required>
                                    {metadata && metadata.direction ? metadata.direction.map((x, i)=>{
                                        return (
                                        <MenuItem 
                                            key={i}
                                            value={x.name}>
                                            {x.name}
                                        </MenuItem>
                                        )
                                    }):<MenuItem></MenuItem>}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control col-md-12 float-right mt-4">
                            <Button className="p-0 bg-white" onClick={()=>this.setState({durationDialog: true})} >
                                <TextField
                                    id="standard-textarea"
                                    label="Duration"
                                    placeholder="Add Duration"
                                    className="col-md-12"
                                    value={drug.drugDuration ? (drug.drugDuration +' '+ drug.drugDurationUnit) : ''}
                                    // error={error.drugDuration}
                                    // helperText="Drug Duration is missing/invalid!"
                                    InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                            </Button>
                                <Dialog
                                    disableBackdropClick
                                    className="duration-dialog"
                                    open={this.state.durationDialog} 
                                    onClose={()=>this.setState({durationDialog: !this.state.durationDialog})}>
                                    <DialogTitle className="d-flex justify-content-center">Duration</DialogTitle>
                                    <DialogContent className="d-flex justify-content-center">
                                        <TextField
                                            id="standard-textarea"
                                            label="Add Duration"
                                            variant="outlined"
                                            className="m-3"
                                            placeholder="Days"
                                            type="number"
                                            onMouseOut={this.getErrorMsg}
                                            value={drug.drugDuration}
                                            onChange={(e)=>this.setState({drugDuration:e.target.value})}
                                            />
                                        <TextField
                                            id="standard-textarea"
                                            variant="outlined"
                                            className="m-3"
                                            onMouseOut={this.getErrorMsg}
                                            value={drug.drugDurationUnit}
                                            onChange={(e)=>this.setState({drugDurationUnit:e.target.value})}
                                            InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                    </DialogContent>
                                    <DialogActions className="d-flex justify-content-center">
                                        <Button variant="outlined" onClick={()=>this.setState({durationDialog: !this.state.durationDialog})} color="primary">
                                            Cancel
                                        </Button>
                                        <Button variant="outlined" onClick={()=>this.setState({durationDialog: !this.state.durationDialog})} color="primary">
                                            Submit
                                        </Button>
                                        </DialogActions>
                                </Dialog>
                        </FormControl>
                        <FormControl className="form-control col-md-12 float-right mt-4">
                            <Button className="p-0 bg-white" onClick={()=>this.setState({instructionDialog: true})}>
                                <TextField
                                    id="standard-textarea"
                                    label="Instruction"
                                    placeholder="Add Instructions"
                                    className="col-md-12"
                                    value={drug.drugInstruction}
                                    InputProps={{
                                        readOnly: true,
                                      }}
                                    error={error.drugInstruction}
                                    // helperText="Drug Instruction is missing/invalid!"
                                    />
                            </Button>
                                <Dialog
                                    disableBackdropClick
                                    className="instruction-dialog"
                                    open={this.state.instructionDialog} 
                                    onClose={()=>this.setState({instructionDialog: !this.state.instructionDialog})}>
                                    <DialogTitle className="d-flex justify-content-center">Instructions</DialogTitle>
                                    <DialogContent className="d-flex justify-content-center">
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Add Instruction"
                                            variant="outlined"
                                            className="m-3 instruction-input col-md-11"
                                            rows={16}
                                            multiline
                                            onMouseOut={this.getErrorMsg}
                                            value={drug.drugInstruction}
                                            onChange={(e)=>this.setState({drugInstruction:e.target.value})}
                                            placeholder="Instructions..." />
                                    </DialogContent>
                                    <DialogActions className="d-flex justify-content-center">
                                        <Button variant="outlined" onClick={()=>this.setState({instructionDialog: !this.state.instructionDialog})} color="primary">
                                            Cancel
                                        </Button>
                                        <Button variant="outlined" onClick={()=>this.setState({instructionDialog: !this.state.instructionDialog})} color="primary">
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                        </FormControl>
                        <FormControl className="form-control col-md-12 mt-5">
                            <Button 
                                variant="outlined"
                                type="submit" 
                                className="col-6 m-auto" 
                                onClick={this.formSubmitHandler} 
                                color="primary">
                                Save Medication
                            </Button>
                        </FormControl>
                    </form>
                </div>
            </div>
        )
    }
}

export default MedicationForm
