import { patchWithToken, postWithToken } from "../lib/request"


/**
 * @description method to call psot api, calling post api to post review ratings
 * @author Jagannath
 * @date 2020-09-29
 * @param data payload
 */
export const postReviewRating = (data) => {
    return postWithToken('/provider/reviewAndRating', data)
}

/**
 * @description PATCH api to change booking status
 * @author Jagannath
 * @date 2020-09-29
 * @param data
 * @return {*} 
 */
export const createPrescription = (data) => {
    return patchWithToken(`provider/bookingStatus`, data)
  }