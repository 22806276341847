import React, { Component } from 'react';
import './DoctorBookings.css';
import {
    Card, 
    Button, 
    Avatar, 
    CircularProgress, 
    Backdrop, 
    Dialog, 
    IconButton 
} from '@material-ui/core';
import moment from 'moment';
import Header from '../header/Header';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getDoctorBookings, getPatientBookings, providerBookingHistory } from '../../services/service';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import UserProfileDialog from '../userProfile/UserProfileDialog';
import { Redirect } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import BookingTables from '../bookings/BookingTables';
import { bookingsHandler } from '../bookings/DailyBookings';
import MenuIcon from '@material-ui/icons/Menu';
import WeekStrip from '../../components/week-strip/WeekStrip';
import RecentBookingCard from '../../components/recent-booking-card/RecentBookingCard';
import { mqttHost } from '../../global/global';
import mqtt from 'mqtt'

/**
 * @description component shows bookings form doctors
 * @author Jagannath
 * @date 2020-09-16
 * @export DoctorBookings Component
 * @class DoctorBookings
 */
export class DoctorBookings extends Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
            data: [],
            isDoctorLogin: true,
            date: new Date(),
            fromDate: new Date().toLocaleString('en-us',{dateStyle:'full'}),
            toDate:null,
            conference: false,
            isLoader: false,
            isBackdrop: false,
            showProfile: false,
            defaultPage: true,
            profileData: [],
            menuClick: false,
            sessionInterval: null,
            recentBookingHistory: [],
            recentBookingFilter: {
                startDate: moment().subtract(7, 'day').valueOf(),
                endDate: Date.now()
            }
          }
        const topic = `booking/${reactLocalStorage.get('userId')}`;
        const client = mqtt.connect(mqttHost)
        client.on('connect', () => {
            console.log('client connected!')
        });
        client.subscribe(topic,{qos:1},()=>{
            // console.log('client subscribed on topic:',topic);
        });
        client.on("error",(error)=>{
            // console.log("Can't connect"+error);
        });

        client.on('message', (topic, message) => {
            setTimeout(() => {
                this.getAllSessions()
            }, 2000);
        });
    }


    /**
     * @description calling booking details from api
     * @author Jagannath
     * @date 2020-09-15
     */
    getAllSessions = () => {
        let toDate = new Date(this.state.date);
        toDate.setDate(toDate.getDate() + 1)
        toDate.setHours(0,0,0,0)
        const list = {
            fromDate: new Date(this.state.date).setHours(0,0,0,0).valueOf()/1000,
            toDate: new Date(toDate).valueOf()/1000
        }
        if (this.state.isDoctorLogin){
            getDoctorBookings(list)
                .then((res)=>{
                    const bookingData = res.data.data.filter(x=>x.callType==3)
                    this.setState({
                        data:bookingData, 
                        isLoader: false
                    })
                })
                .catch((err)=>{
                    console.log(err);
                    this.setState({isLoader:false})
                })
        }else{
            getPatientBookings(list)
                .then((res)=>{
                    console.log(res.data.data.upcoming)
                    this.setState({data:res.data.data.upcoming, isLoader: false})
                })
                .catch((err)=>{
                    console.log(err);
                    this.setState({isLoader:false})
                }) 
        }
    }

    /**
     * @description method to get recent booking history
     * @author Jagannath
     * @date 2020-10-13
     */
    getRecentBookings = () => {
        
        if(this.state.isDoctorLogin){
            providerBookingHistory(this.state.recentBookingFilter)
            .then((res)=>{
                console.log(res);
                this.setState({recentBookingHistory: res.data.data})
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }

    /**
     * @description insitially showing loader 
     * @author Jagannath
     * @date 2020-09-15
     */
    componentDidMount(){

        this.setState({
            isLoader:true,
            isDoctorLogin: reactLocalStorage.get('userType') == 2 ? false : true
        }) 
        this.getRecentBookings()
        this.getAllSessions();
        setInterval(() => {
            if(window.innerWidth > 780 && this.state.menuClick){
                console.log('inner width', this.state.menuClick)
                this.setState({menuClick: false});
            }
        }, 1000);
    }

    /**
     * @description get bookings by date filter on selecting a particular date
     * @author Jagannath
     * @date 2020-09-15
     * @param date selected date
     */
    dateSelectHandler = async(date) => {
        this.setState({isLoader: true, data: [], defaultPage: false})
        await this.setState({
            date: date, 
            fromDate: new Date(date).toLocaleString('en-us',{dateStyle:'full'})
        });
        await this.getAllSessions();
    }

    /**
     * @description on clicking booking card
     * set state for opeing profile dialog and
     * set booking id to presistant storate
     * @author Jagannath
     * @date 2020-09-15
     * @param patient patient details
     */
    openProfileDialog = (patient) =>{
        reactLocalStorage.set('bookingId', patient.bookingId)
        this.setState({
            showProfile: true,
            profileData: patient
        })
    }

    /**
     * @description method to open/close profile dialog
     * @author Jagannath
     * @date 2020-09-15
     * @param value boolean value 
     */
    profileDialogHandler = ( value ) => {
        this.setState({showProfile: value})
    }

    /**
     * @description dialog component for profile
     * added UserProfileDialog to show profile details in dialog
     * @author Jagannath
     * @date 2020-09-15
     */
    showProfilePage = () => {
        return (
            <Dialog
                className="dialog"
                open={this.state.showProfile}
                onClose={(e)=>this.profileDialogHandler(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <UserProfileDialog 
                    data={this.state.profileData}
                    profileDialogHandler = {(e)=>this.profileDialogHandler(false)}
                /> 
            </Dialog>
        )
    }

    /**
     * @description method to handle week selcect in weekselection component
     * @author Jagannath
     * @date 2020-10-13
     * @param range selected week range
     */
    onSelectWeekRange = (range) =>{
        this.setState({recentBookingFilter: range},()=>{
            this.getRecentBookings()
        });
    }

    
    
    /**
     * @description Final rendering the component
     * @author Jagannath
     * @date 2020-09-15
     * @return {*} 
     */
    render() {
        if(this.state.conference){
            return <Redirect to="/conference" />
        }
        return (
            <div className="bg-white home">
                {/* calling profile dialog here */}
                {this.showProfilePage()}
                
                {/* showing loader  */}
                <Backdrop open={this.state.isBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                {/* page header */}
                <Header doctor={this.state.isDoctorLogin} />

                {/* page body data */}
                <div className="col-12 container doctorBgColor">

                    {/* left side bar containing callenders */}
                    <Card className={`left-sidebar sidebar doctorBgColor mr-4 ml-4 mt-4 ${this.state.menuClick ? 'disp-block' : 'disp-none'}`} variant="outlined">
                        <div className='MuiPaper-elevation4 mb-4'>
                        <div className="col-12 appoinment-header p-2">
                            <b>Appoinments</b>
                            <Button variant="contained" color="primary" className="appoinment-count">{this.state.data.length}</Button>
                        </div>
                        {/* <hr/> */}
                        <div className="calender cal-one">
                            <Calendar
                                className='doctorBgColor'
                                onChange={date => this.dateSelectHandler(date)}
                                value={this.state.date}
                            />
                        </div>
                        </div>
                        {/* <hr/> */}
                        <div className="calender cal-two MuiPaper-elevation4">
                            <div className="col-12 appoinment-header p-2">
                                <b>Recent Appoinments</b>
                            </div>
                            <WeekStrip weekrange={(range)=>this.onSelectWeekRange(range)}/>
                            <div className="recent-booking-listing">
                                {
                                this.state.recentBookingHistory.length ?
                                    this.state.recentBookingHistory.map((x,i)=>{
                                        return <RecentBookingCard key={i} data={x} />
                                    })
                                :   <div className="text-center">
                                        <h6 className="text-muted p-5">No Recent bookings</h6>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </Card>
                    
                    {/* right sidebar containing booking details and profile dialog */}
                    <div className="right-sidebar sidebar mt-4">
                        <div className="d-flex">
                            <div className="right-sidebar-menu">
                                <IconButton 
                                    className="mt-1" 
                                    color="inherit" 
                                    onClick={()=>this.setState({menuClick: !this.state.menuClick})}
                                    aria-label="menu">
                                    <MenuIcon className="menu-icon" />
                                </IconButton>
                            </div>
                            <div className="mt-3 pl-2">
                                <b className="header-date mb-3">{String(this.state.fromDate)}</b>
                                <AvatarGroup max={10} className="mt-2">
                                    {this.state.data.map((value, index)=>{
                                        return <div key={index}>
                                            {value.profilePic ? 
                                                (<Avatar 
                                                    src={value.profilePic}
                                                    alt="icon"
                                                    className="float-left rounded-circle" />)
                                                    : (<span className="float-left rounded-circle text-img">
                                                    {value.firstName[0] + value.lastName[0]}
                                                </span>)
                                            }
                                        </div>
                                    })}
                                </AvatarGroup>
                            </div>
                        </div>
                        <hr/>

                        {/* showing default bookings for 10 days and for selected date bookings */}
                        {
                            this.state.isLoader ? 
                                <div className="loader">
                                    <CircularProgress />
                                </div>
                            : this.state.defaultPage 
                                ? <BookingTables stateData={this.state} openProfileDialog={this.openProfileDialog} />
                                : bookingsHandler(this.state, this.openProfileDialog)
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DoctorBookings
