// guellapp caliing url for doctor

export const doctorCallingUrl = 'https://meet.guellapp.com/';
export const baseUrl = 'https://api.guellapp.com/'

// export const baseUrl = 'http://localhost:3000/'
// export const baseUrl = 'http://10.10.10.114:3000/'

// MQTT config
export const mqttHost = 'wss://mqtt.guellapp.com:2083/mqtt';
