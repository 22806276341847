import React, { Component } from 'react'
import './Conference.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Redirect } from 'react-router-dom';
import {config} from './config';
import { baseUrl } from '../../global/global';

/**
 * @description component for conference
 * renders conference into full screen
 * @author Jagannath
 * @date 2020-09-15
 * @export Conference component
 * @class Conference
 */
export default class Conference extends Component {
    
    /**
     * @description initial component lifecycle
     * getting jwt, booknigId, and all stuffs needed for conference
     * @author Jagannath
     * @date 2020-09-15
     */
    componentDidMount(){
        config.jwt = reactLocalStorage.get('conferenceToken')
        config.roomName = reactLocalStorage.get('bookingId')
        config.parentNode = document.querySelector('#data')
        var domain = "meet.guellapp.com";
        var options = config
        var api = new window.JitsiMeetExternalAPI(domain, options); 
        api.addEventListener('readyToClose',  function(){
            console.log('call hung up fron add Event Listener Event');
            window.location = `${baseUrl}thankyou`
        });
    }   
    render() {
        if(!reactLocalStorage.get('token')){
            return <Redirect to="/thankyou" />
        }
        return (
            <div id="data" className="responsive-iframe"></div>
        )
    }
}
